import React, { FunctionComponent, useEffect, useState } from 'react'
import { matchPath, RouteComponentProps, StaticContext } from 'react-router'
import {
  AnnounceMode,
  IClinAnnounceBarProps
} from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { OrderCancellationCodes } from '../../constants'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { OrderLineCancellationModal } from '../../features/OrderLineCancellationModal'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  AuthError,
  cancelGetEnrolledPhysicians,
  cancelGetEnrolledPrograms,
  cancelGetProgramFromSearchIndexById,
  cancelGetReadOnlyOpaUrl,
  getEnrolledPhysicians,
  getEnrolledPrograms,
  getProgramFromSearchIndexById,
  getReadOnlyOpaUrl,
  removeOrder,
  requestOrderInvoice,
  setDocumentToHold
} from '../../services/ApiService'
import {
  getHoldDocumentForHold,
  useOrderLevelHolds
} from '../../services/Holds/holds'
import { getHoldForType, HoldType } from '../../services/Holds/holds.constants'
import { IError } from '../../types'
import { findOrderCancellationLookupCode } from '../../types/IOrder'
import {
  DeleteOrderDto,
  HoldDto,
  InstituteDto,
  OrderDto,
  OrderLineDto,
  PhysiciansEnrolledSummaryDto,
  ProgramCatalogDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import { useErrorMessage } from '../../utils/useErrorMessage'
import { isOrderCancellable } from '../OpaCheckout/OpaCheckout.models'
import { getUpdatedOrderState } from '../OrderPages/OrderDetail/OrderDetail.model'
import { MaOrderDetail } from './MaOrderDetail'
import {
  getTrackingInfo,
  isEnrolledProgram,
  isPhysicianAssociated
} from './MaOrderDetail.models'
import { AnalyticsEvent } from '../../services/Analytics'
import { OrderStatus } from '../../types/OrderStatus'
import { AxiosError } from 'axios'
import { IHoldDetails } from '../../services/Holds/holds.types'
import { useTranslation } from 'react-i18next'
import { IRestEndpointState } from '../../types/IRestEndpointState'
import { useEffectOnlyOnce } from '../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsPageEvent } from '../../services/Analytics/AnalyticsPageEvent'
import { defaultEnrolledSearchParams } from '../Patients/CreatePatient/CreatePatient.model'

type LocationState = {
  endpoint?: string
  from: string
  orderDto?: OrderDto
}

export interface IMaOrderDetailProps
  extends RouteComponentProps<{}, StaticContext, LocationState> {}

export const MaOrderDetailContainer: FunctionComponent<IMaOrderDetailProps> = ({
  history,
  location
}) => {
  const { t } = useTranslation()
  const { orderDto } = location.state
  const matchPatientDetails =
    history.location.state &&
    matchPath(history.location.state.from, {
      path: `/programs/my-physicians/:physicianId/:patientId`
    })
  const backButtonTitle = matchPatientDetails
    ? t('ma_order_detail:back_patient_details_button')
    : t('common:buttons.back')
  const [isProgramLoading, setIsProgramLoading] = useState<boolean>(true)
  const [isPhysiciansLoading, setIsPhysiciansLoading] = useState<boolean>(true)
  const [isLoadingPatientAccessForm, setIsLoadingPatientAccessForm] =
    useState(true)
  const [order, setOrder] = useState<OrderDto | undefined>(orderDto)
  const [program, setProgram] = useState<ProgramCatalogDto>()
  const [physicians, setPhysicians] = useState<PhysiciansEnrolledSummaryDto[]>()
  const [patientAccessFormUrl, setPatientAccessFormUrl] = useState<
    string | undefined
  >()
  const [isCancelOrderlineModalOpen, setIsCancelOrderlineModalOpen] =
    useState<boolean>(false)
  const [deleteOrderDescription, setDeleteOrderDescription] = useState<
    DeleteOrderDto | undefined
  >()
  const [cancellationSubmitting, setCancellationSubmitting] =
    useState<boolean>(false)
  const { institute } = useAppContext()
  const cancellationOptions =
    OrderCancellationCodes && Object.values(OrderCancellationCodes)
  const [cancellationHasError, setCancellationHasError] = useState<IError>()
  const [isLoadingCancelOrder, setIsLoadingCancelOrder] =
    useState<boolean>(false)
  const { supportContact, dispatch } = useAppContext()

  const [holdDetails, setHoldDetails] = useState<IHoldDetails[]>([])
  const orderLevelHolds = useOrderLevelHolds(undefined, order, supportContact)
  const [enrolledPrograms, setEnrolledPrograms] = useState<string[]>([])

  const [orderAnnounce, setOrderAnnounce] = React.useState<
    IClinAnnounceBarProps | undefined
  >()

  const handleAccessFormUrlError = useErrorMessage(
    `There was an error fetching patient access form URL.`
  )
  const handleProgramError = useErrorMessage(
    `There was an error fetching program.`
  )
  const handleEnrolledPhysiciansError = useErrorMessage(
    `There was an error fetching the enrolled physicians.`
  )
  const programId = order?.lines[0].programId?.toString()
  const treatingPhysicianId = order?.lines[0].physician?.physicianId
  const isPhysicianAssociatedToUser: boolean = isPhysicianAssociated(
    treatingPhysicianId,
    physicians
  )
  const isEnrolledProgramToUser: boolean = isEnrolledProgram(
    enrolledPrograms,
    programId
  )

  useEffect(() => {
    if (orderLevelHolds.length) {
      setHoldDetails([getHoldForType(HoldType.CustomDelivery)])
    }
  }, [orderLevelHolds])

  useEffect(() => {
    programId &&
      getProgramFromSearchIndexById(programId)
        .then((result) => {
          setProgram(result.data)
        })
        .catch((error) => handleProgramError(error))
        .finally(() => setIsProgramLoading(false))
    return () => {
      cancelGetProgramFromSearchIndexById()
    }
  }, [handleProgramError, programId])

  useEffect(() => {
    // Only make this call if is a physician associated to the user
    if (
      isPhysicianAssociatedToUser &&
      order?.opaAccessToken &&
      order?.orderNumber
    ) {
      getReadOnlyOpaUrl(Number(order?.orderNumber), {
        opaAccessToken: order?.opaAccessToken
      })
        .then((response) => {
          if (response.data.endPoint) {
            setPatientAccessFormUrl(response.data.endPoint)
          }
        })
        .catch((error) => handleAccessFormUrlError(error))
        .finally(() => setIsLoadingPatientAccessForm(false))
    } else if (order) {
      setIsLoadingPatientAccessForm(false)
    }
    return () => {
      cancelGetReadOnlyOpaUrl()
    }
  }, [handleAccessFormUrlError, order, isPhysicianAssociatedToUser])

  useEffect(() => {
    getEnrolledPrograms(defaultEnrolledSearchParams)
      .then((response) => {
        if (response) {
          setEnrolledPrograms(
            response.data.result.map((program) => program.programId.toString())
          )
        }
      })
      .catch((error: AxiosError) => {
        const { code, message } = error

        if (error.message === AuthError.RequestCancelled) {
          return
        }
        if (error.response) {
          const { title } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching enrolled access programs. ${
                title ? title : ''
              } ${message ? message : ''}`
            )
          )
        } else {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching enrolled access programs. ${message} ${
                code ? code : ''
              }`
            )
          )
        }

        return () => {
          cancelGetEnrolledPrograms()
        }
      })
  }, [programId, dispatch])

  useEffect(() => {
    programId &&
      getEnrolledPhysicians(programId)
        .then((response) => {
          response && setPhysicians(response.data.physicians)
        })
        .catch((error) => handleEnrolledPhysiciansError(error))
        .finally(() => {
          setIsPhysiciansLoading(false)
        })

    return () => {
      cancelGetEnrolledPhysicians()
    }
  }, [handleEnrolledPhysiciansError, programId])
  type Deps = [OrderDto, IRestEndpointState<InstituteDto>, string]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(AnalyticsPageEvent.ViewOrder, {
        'Order number': dependencies[0].orderNumber,
        Institution: dependencies[1].data?.instituteName
      })
    },
    [order, institute],
    (dependencies: Deps) => dependencies[0] && dependencies[1]
  )

  const handlePatientAccessForm = () => {
    const physicianId = order?.lines[0].physician?.physicianId
    programId && patientAccessFormUrl
      ? history.push(
          `/programs/access-programs/${programId}/patient-access-form/${physicianId}`,
          {
            endpoint: patientAccessFormUrl,
            from: window.location.pathname
          }
        )
      : history.push('/about/contact-us')
  }

  const handlePlaceOrderAgain = () => {
    order &&
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.PlaceOrderMAAgain, {
        programId,
        orderId: order.orderNumber
      })
    history.push(`/programs/access-programs/${programId}`)
  }

  const handleCancelOrder = () => {
    const updateOrderLineDto: DeleteOrderDto = {
      cancelReason: '',
      cancelComments: ''
    }
    setDeleteOrderDescription(updateOrderLineDto)
    setIsCancelOrderlineModalOpen(true)
  }

  const handleCancellationOptionChange = (id: string) => {
    if (deleteOrderDescription) {
      const updateDeleteOrderDesc: DeleteOrderDto = {
        ...deleteOrderDescription,
        cancelReason: id ? findOrderCancellationLookupCode[id] : ''
      }
      setDeleteOrderDescription(updateDeleteOrderDesc)
    }
  }

  const handleCancellationTextChange = (comment: string) => {
    if (deleteOrderDescription) {
      const updateOrderLineDto: DeleteOrderDto = {
        ...deleteOrderDescription,
        cancelComments: comment
      }
      setDeleteOrderDescription(updateOrderLineDto)
    }
  }

  const handleSubmitCancellation = () => {
    if (order && deleteOrderDescription) {
      setCancellationSubmitting(true)
      setIsLoadingCancelOrder(true)
      removeOrder(order.orderNumber, deleteOrderDescription)
        .then(() => {
          setIsCancelOrderlineModalOpen(false)
          analyticsServiceSingleton.trackEvent(AnalyticsEvent.CancelOrderMA, {
            orderId: order.orderNumber,
            programId,
            location: 'Ma Order Detail'
          })
          history.push('/orders')
        })
        .catch((error) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          setCancellationHasError({
            hasError: true,
            errorMessage: `There was an error when cancelling your item. ${error}`
          })
          analyticsServiceSingleton.trackError(
            error,
            JSON.parse(localStorage.getItem('current_user') || '{}')
          )
        })
        .finally(() => {
          setCancellationSubmitting(false)
          setIsLoadingCancelOrder(false)
        })
    }
  }

  const handleCancellationClose = () => {
    setIsCancelOrderlineModalOpen(false)
    setDeleteOrderDescription(undefined)
  }

  const handleEmailOrderDetails = () => {
    if (order?.orderStatus === OrderStatus.Shipped.toString()) {
      requestOrderInvoice(order?.orderNumber)
        .then((response) => {
          if (response.status === 200) {
            setOrderAnnounce({
              title: ' ',
              message: t('ma_order_detail:invoice_email_sent'),
              mode: AnnounceMode.Information,
              isDismissible: true
            })
          }
        })
        .catch((error: AxiosError) => {
          setOrderAnnounce({
            title: 'Error',
            message: `${t('ma_order_detail:invoice_email_error')} ${error}`,
            mode: AnnounceMode.Error,
            isDismissible: true
          })
        })
    } else {
      setOrderAnnounce({
        message: t('ma_order_detail:invoice_email_error'),
        mode: AnnounceMode.Warning,
        isDismissible: true
      })
    }
  }

  const handleGoBack = () => {
    if (
      (history.location.state && history.location.state.from === '/orders') ||
      matchPatientDetails
    ) {
      history.goBack()
    } else {
      history.push('/orders')
    }
  }

  const handleFileUploadedForOrderAndHold = (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => {
    const holdDocumentType: string | undefined = getHoldDocumentForHold(hold)
    if (hold.holdId && holdDocumentType) {
      setDocumentToHold(
        orderId,
        document.uploadedDocumentId,
        hold,
        holdDocumentType
      )
        .then((response) => {
          if (response.status > 200 && response.status <= 299) {
            // Update order with document details
            if (order) {
              setOrder(
                getUpdatedOrderState(
                  order,
                  orderLine,
                  hold,
                  holdDocumentType,
                  document
                )
              )
            }
          }
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred uploading your document. ${error}`
            )
          )
          // Trigger refresh to remove document
          window.scrollTo(0, 0)
        })
    } else {
      console.warn(
        'No holdId or holdDocumentType supplied to setDocumentToHold'
      )
    }
  }

  const listOfNewFeatures = undefined //expired features

  return (
    <>
      <OrderLineCancellationModal
        isOpen={isCancelOrderlineModalOpen}
        selectedOption={
          deleteOrderDescription &&
          findOrderCancellationLookupCode[deleteOrderDescription?.cancelReason]
        }
        cancellationOptions={cancellationOptions}
        isSubmitting={cancellationSubmitting}
        handleCommentChange={handleCancellationTextChange}
        handleOptionChange={handleCancellationOptionChange}
        handleSubmit={handleSubmitCancellation}
        handleClose={handleCancellationClose}
        hasError={cancellationHasError && cancellationHasError.hasError}
        errorMessage={cancellationHasError && cancellationHasError.errorMessage}
      />
      <MaOrderDetail
        isLoading={
          isLoadingPatientAccessForm ||
          isProgramLoading ||
          isPhysiciansLoading ||
          isLoadingCancelOrder
        }
        isOrderCancellable={order && isOrderCancellable(order)}
        newFeaturesList={listOfNewFeatures}
        order={order}
        program={program}
        orderLevelHolds={holdDetails}
        patientAccessFormUrl={patientAccessFormUrl}
        backButtonTitle={backButtonTitle}
        isPhysicianAssociated={isPhysicianAssociatedToUser}
        isEnrolledProgram={isEnrolledProgramToUser}
        orderAnnounce={orderAnnounce}
        trackingInfo={order && getTrackingInfo(order)}
        handleDismissAnnounce={() => setOrderAnnounce(undefined)}
        handlePatientAccessForm={handlePatientAccessForm}
        handleCancelOrder={handleCancelOrder}
        handlePlaceOrderAgain={handlePlaceOrderAgain}
        handleGoBack={handleGoBack}
        handleFileUploadedForOrderAndHold={handleFileUploadedForOrderAndHold}
        handleEmailOrderDetails={handleEmailOrderDetails}
      />
    </>
  )
}
