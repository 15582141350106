import React, { FunctionComponent } from 'react'
import { ClinText } from '../../components/ClinText'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { DrugPropertyGrid } from '../DrugPropertyGrid'
import { ClinButtonIcon } from '../../components/ClinButtonIcon'
import { ClinTwoColumnCard } from '../../components/ClinTwoColumnCard'
import {
  IAugmentedCatalogItemDto,
  UIVariationState
} from '../../services/PortfolioJourneys/PortfolioJourney.types'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { ClinSpacer } from '../../components/ClinSpacer'
import {
  shouldDisplaAvailability,
  shouldDisplayEstimatedDeliveryDate
} from './VariantCard.model'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { EstimatedDeliveryDate } from '../../components/ClinEstimatedDeliveryDate/EstimatedDeliveryDate'
import { EstimatedDeliveryDateAus } from '../../components/ClinEstimatedDeliveryDate/EstimatedDeliveryDateAus'
import { ProductSourceSystem } from '../../pages/Products/Product.models'

interface IVariantCardProps {
  /** Catalogue item to display */
  catalogueItem: IAugmentedCatalogItemDto
  /** Expose onClick for card */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  /** Text for message that leads to variant/SKU details page */
  cta: string
  /** Shows a delete icon and calls this call back when the user clicks on it */
  onClickDelete?: () => void
  /** Whether to show an overlay saying the item has been deleted */
  isDeleted?: boolean
  /** Whether to show Minimum order message */
  showGBMinimumOrderMessage?: boolean

  countryCode?: string
}

/**
 * Greys out the card if the item isn't available
 * Calculates if the cat item is available or not
 * @param catalogueItem
 */
export const getUnavailableStatus = (
  catalogueItem: IAugmentedCatalogItemDto
) => {
  return (
    catalogueItem.cannotOrder &&
    !catalogueItem.canRegisterInterest &&
    !catalogueItem.canAccessSourcingRequestForm &&
    catalogueItem.uiVariantState !== UIVariationState.AvailableDistributor &&
    catalogueItem.uiVariantState !== UIVariationState.Undefined
  )
}

export const VariantCard: FunctionComponent<IVariantCardProps> = ({
  catalogueItem,
  countryCode,
  onClick,
  cta,
  children,
  onClickDelete,
  isDeleted,
  showGBMinimumOrderMessage
}) => {
  const eDate = catalogueItem.expectedDeliveryDates?.find(
    (e) => e.shipToCountry === countryCode
  )
  const expectedDeliveryDate = eDate?.expectedDeliveryDate
  const cutOffTime = eDate?.shipmentCutOffTime
  const isPastCutOffTime =
    DateTime.now().toUTC() >
    DateTime.fromISO(cutOffTime || '', {
      zone: 'utc'
    })
  const { t } = useTranslation()
  return (
    <ClinTwoColumnCard
      onClick={(event) => onClick && onClick(event)}
      cta={cta}
      isDeleted={isDeleted}
      left={
        <DrugPropertyGrid
          catalogueItem={catalogueItem}
          showUnavailable={getUnavailableStatus(catalogueItem)}
        >
          {children}
        </DrugPropertyGrid>
      }
      right={
        <>
          <ClinText
            as="div"
            color={ClinTheme.colors.primary}
            fontWeight={ClinTheme.fontWeights.medium}
          >
            {t('glossary:product_code')}:
            {onClickDelete && (
              <ClinButtonIcon
                iconName={ClinIconPathName.Cross}
                onClick={(e) => {
                  e.stopPropagation()
                  onClickDelete()
                }}
                hoverColor={ClinTheme.colors.primaryMid}
              />
            )}
          </ClinText>
          <ClinText
            as="div"
            variant={TypographyVariant.LargeParagraph}
            color={ClinTheme.colors.primary}
            marginTop={ClinTheme.space[1]}
            marginBottom={ClinTheme.space[3]}
          >
            {catalogueItem.sku}
          </ClinText>
          {/*<>{JSON.stringify(catalogueItem, null, 2)}</>*/}
          {/* we don't want to display availability for aus user where product division is sp - CLOS-2750 */}
          {shouldDisplaAvailability(catalogueItem, countryCode!) && (
            <ClinText
              as="div"
              color={catalogueItem.availableColour}
              fontSize={ClinTheme.fontSizes[2]} //have changed font size and font weight for availability according to changes in design on 03.08.23
              fontWeight={ClinTheme.fontWeights.medium}
              marginBottom={ClinTheme.space[3]}
            >
              {catalogueItem.availableText}
            </ClinText>
          )}
          {/* Estimated delivery date for non australia users */}
          {/* {countryCode !== CountryAlphaCodes.Australia &&
            shouldDisplayEstimatedDeliveryDate(
              expectedDeliveryDate,
              isPastCutOffTime,
              catalogueItem
            ) && (
              <EstimatedDeliveryDate
                expectedDeliveryDate={expectedDeliveryDate}
                cutOffTime={cutOffTime}
              />
            )} */}
          {/* we have different design for austraia users estimated delivery date */}
          {/* {countryCode === CountryAlphaCodes.Australia &&
            catalogueItem.item.sourceSystem === ProductSourceSystem.Sagex3 && (
              <EstimatedDeliveryDateAus
                isLimitedAvailable={catalogueItem.item.supplyProblem === 'Y'}
                stockLevel={
                  catalogueItem.reservableStock
                    ? catalogueItem.reservableStock
                    : 0
                }
              ></EstimatedDeliveryDateAus>
            )} */}

          <ClinSpacer />
          {showGBMinimumOrderMessage &&
            catalogueItem.uiVariantState ===
              UIVariationState.AvailableAccurateStockLevel &&
            !catalogueItem.cannotOrder && (
              <ClinText
                as="div"
                marginTop={-ClinTheme.space[3]}
                marginBottom={ClinTheme.space[3]}
              >
                {t('product_variants:minimum_order_quantity_warning')}
              </ClinText>
            )}
          {/* For australian users we always display estimated delivery (1-3 days if we have items in stock, and 7-14 if items are out of stock or 0) */}
          {countryCode !== CountryAlphaCodes.Australia &&
            catalogueItem.item.sourceSystem !== ProductSourceSystem.Sagex3 && (
              <ClinText as="div" marginBottom={ClinTheme.space[3]}>
                {catalogueItem.dispatchText}
              </ClinText>
            )}
          <ClinText
            as="div"
            color={ClinTheme.colors.primaryMid}
            marginBottom={ClinTheme.space[5]}
          >
            {catalogueItem.item.maximumOrderQuantity &&
              t('product_variants:maximum_order_quantity_warning', {
                maximumOrderQuantity: catalogueItem.item.maximumOrderQuantity
              })}
          </ClinText>
        </>
      }
    />
  )
}
